

























import { computed, defineComponent, PropType } from "@vue/composition-api";

import { useLocations } from "@/lib/locations";

import LocationItem from "./Location.vue";
import { FieldsConfig } from "./Form.vue";
import { LocationType } from "@/types/locations/location";

export default defineComponent({
  props: {
    types: {
      type: Array as PropType<LocationType[]>,
      default: () => Object.values(LocationType)
    },
    create: { type: Boolean, default: false },
    fields: { type: Object as PropType<FieldsConfig> }
  },
  components: { LocationItem },
  setup({ types: _types }) {
    const { get, getAll, filterByType, list } = useLocations();
    get();
    const getByType = (type: LocationType) =>
      filterByType(getAll(), type)[0] || { value: { type } };
    const locations = computed(() => _types.map(getByType));
    const listItem = computed(() => list());
    const loading = computed(() => !!listItem.value?.loading);
    return { locations, loading, listItem };
  }
});
